import { useLocation } from "react-router-dom";

import {
  Sidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarBody,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "../../common/sidebarCatalyst";
import { WorkspaceSwitcher } from "../../HomePages/Workspaces/Workspace_switcher";
import { UserMenu } from "../Sidebar_navigation/User_menu";

import {
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  ListBulletIcon,
  BoltIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";
import { TiFlowSwitch } from "react-icons/ti";
import { Avatar } from "../../common/avatar";

const initialNavigation = [
  { name: "Dashboard", href: "dashboard", icon: <HomeIcon />, current: false },
  {
    name: "Knowledge base",
    href: "knowledge",
    icon: <FolderIcon />,
    current: false,
  },
  { name: "Threads", href: "threads", icon: <UsersIcon />, current: false },
  {
    name: "Contacts",
    href: "contacts",
    icon: <IdentificationIcon />,
    current: false,
  },
  {
    name: "Prompt",
    href: "prompt",
    icon: <DocumentDuplicateIcon />,
    current: false,
  },
  { name: "Modules", href: "modules", icon: <ChartPieIcon />, current: false },
  {
    name: "Campaigns",
    href: "campaign",
    icon: <ListBulletIcon />,
    current: false,
  },
  // {
  //   name: "Workflows",
  //   href: "workflows",
  //   icon: <TiFlowSwitch />,
  //   current: false
  // },
  {
    name: "Integrations",
    href: "integrations",
    icon: <BoltIcon />,
    current: false,
  },
  {
    name: "API Settings",
    href: "settings",
    icon: <Cog6ToothIcon />,
    current: false,
  },
];

export function SidebarNavigation(props) {
  const {
    projects,
    activeProject,
    setActiveProject,
    user,
    firstNameShort,
    setIsOpen,
  } = props;

  const location = useLocation();

  const navigationItems = initialNavigation.map((nav) => ({
    ...nav,
    current: nav.href === location.pathname.split("/")[1],
  }));

  const hideLogo = localStorage.getItem("hideLogo") === "true";

  return (
    <Sidebar>
      {!hideLogo && <SidebarHeader>
        <div className="flex mx-auto">
          <img src="/light.svg" />
        </div>

      </SidebarHeader>}
      <SidebarBody>
        <SidebarSection>
          <WorkspaceSwitcher
            projects={projects}
            activeProject={activeProject}
            setActiveProject={setActiveProject}
            setIsOpen={setIsOpen}
          />
          <SidebarSpacer />
        </SidebarSection>
        {navigationItems.map((item) => (
          <SidebarItem
            key={item.href}
            to={item.href}
            current={item.current}
            active={item.current}
          >
            {item.icon}
            <SidebarLabel>{item.name}</SidebarLabel>
          </SidebarItem>
        ))}
      </SidebarBody>
      <SidebarFooter>
        <UserMenu initials={firstNameShort} user={user} />
      </SidebarFooter>
    </Sidebar>
  );
}
