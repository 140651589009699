import React from "react";
import { Button } from "../../common/button";

function GHL({ projectId }) {
  const connectGHL = async (region) => {
    localStorage.setItem("projectId", projectId);
    localStorage.setItem("authCategory", "ghlCRM");

    const options = {
      baseURL: "https://marketplace.gohighlevel.com/",
      requestType: "code",
      redirectUri: process.env.REACT_APP_ZOHO_REDIRECT,
      clientId: process.env.REACT_APP_GHL_CLIENT_ID,
      scopes: [
        // "calendars.readonly",
        // "calendars.write",
        // "calendars.events.readonly",
        // "calendars.events.write",
        // "calendars.groups.readonly",
        // "calendars.groups.write",
        // "calendars.resources.readonly",
        // "calendars.resources.write",
        "calendars.readonly",
        "calendars.write",
        "calendars/events.readonly",
        "calendars/events.write",
        "calendars/groups.readonly",
        "calendars/groups.write",
        "calendars/resources.readonly",
        "calendars/resources.write",
        "companies.readonly",
        "users.readonly",
        "contacts.readonly",
        "contacts.write",
        /* GHL App */
        "conversations.readonly",
        "conversations/message.readonly",
        "conversations/message.write",
        "locations.readonly",
        "conversations.write"
      ],
    };

    const scopeString = options.scopes.join(" ");

    // Construct the GHL OAuth URL
    const ghlAuthUrl =
      `${options.baseURL}oauth/chooselocation?` +
      `response_type=${encodeURIComponent(options.requestType)}&` +
      `redirect_uri=${encodeURIComponent(options.redirectUri)}&` +
      `client_id=${encodeURIComponent(options.clientId)}&` +
      `scope=${encodeURIComponent(scopeString)}`;

    window.open(ghlAuthUrl, "_blank");
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 border rounded-lg">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            GHL Integration
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Give restricted access to GHL for functions like contact search,
              CRM updates, and more.
            </p>
          </div>

          <div className="">
            <Button onClick={connectGHL} className="mt-10 w-full">
              Connect GHL
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GHL;
