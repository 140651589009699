import { useEffect, useRef } from "react";
import {
  useReactFlow,
  useStore,
  Node,
  Edge,
  ReactFlowState,
} from "@xyflow/react";
import { stratify, tree } from "d3-hierarchy";

const layout = tree()
  .nodeSize([80, 300])
  .separation(() => 5);

const options = { duration: 300 };

function layoutNodes(nodes, edges) {
  if (nodes.length === 0) {
    return [];
  }
  const hierarchy = stratify()
    .id((d) => d.id)
    .parentId((d) => edges.find((e) => e.target === d.id)?.source)(nodes);

  const root = layout(hierarchy);

  console.log({ root, hierarchy });

  return root.descendants().map((d) => {
    if (d.id === "end") {
      return { ...d.data, position: { x: 0, y: d.y } };
    }

    return { ...d.data, position: { x: d.x, y: d.y } };
  });
}

const nodeCountSelector = (state) => state.nodeLookup.size;

function useLayout() {
  const initial = useRef(true);

  const nodeCount = useStore(nodeCountSelector);

  const { getNodes, getNode, setNodes, setEdges, getEdges, fitView } =
    useReactFlow();

  useEffect(() => {
    const nodes = getNodes();
    const edges = getEdges();

    const targetNodes = layoutNodes(nodes, edges);

    setNodes(targetNodes);

    if (!initial.current) {
      fitView({ duration: 200, padding: 0.2 });
    }
    initial.current = false;

    // // to interpolate and animate the new positions, we create objects that contain the current and target position of each node
    // const transitions = targetNodes.map((node) => {
    //   return {
    //     id: node.id,
    //     // this is where the node currently is placed
    //     from: getNode(node.id)?.position || node.position,
    //     // this is where we want the node to be placed
    //     to: node.position,
    //     node,
    //   };
    // });

    // // create a timer to animate the nodes to their new positions
    // const t = timer((elapsed: number) => {
    //   const s = elapsed / options.duration;

    //   const currNodes = transitions.map(({ node, from, to }) => {
    //     return {
    //       ...node,
    //       position: {
    //         // simple linear interpolation
    //         x: from.x + (to.x - from.x) * s,
    //         y: from.y + (to.y - from.y) * s,
    //       },
    //     };
    //   });

    //   setNodes(currNodes);

    //   // this is the final step of the animation
    //   if (elapsed > options.duration) {
    //     // we are moving the nodes to their destination
    //     // this needs to happen to avoid glitches
    //     const finalNodes = transitions.map(({ node, to }) => {
    //       return {
    //         ...node,
    //         position: {
    //           x: to.x,
    //           y: to.y,
    //         },
    //       };
    //     });

    //     setNodes(finalNodes);

    //     // stop the animation
    //     t.stop();

    //     // in the first run, fit the view
    //     if (!initial.current) {
    //       fitView({ duration: 200, padding: 0.2 });
    //     }
    //     initial.current = false;
    //   }
    // });

    // return () => {
    //   t.stop();
    // };
  }, [nodeCount, getEdges, getNodes, getNode, setNodes, fitView, setEdges]);
}

export default useLayout;
