import React, { useState, useEffect, useMemo } from "react";
import Form from "../../../components/Form";
import { message } from "antd";
import * as customActionService from "../../../services/customActions";
import { useFields } from "./models/useFields";

function AddNewTrigger({
  tabState,
  projects,
  activeProject,
  settings,
  setTabState,
  onCancel,
  reload,
  setReload,
}) {
  const [inputField, setInputField] = useState({
    actionName: "",
    phoneNumber: "",
    description: "",
    contentText: "",
    triggerEvent: "duringCall",
  });

  const [humanagents, setHumanAgents] = useState([]);
  const [forWhatsapp, setForWhatsapp] = useState(false);
  const [previewData, setPreviewData] = useState(inputField);
  const { template, schema } = useFields(previewData);

  // const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const addTriggerButtonInfo = {
    buttons: "2",
    primaryLabel: "Add Trigger",
    secondaryLabel: "Cancel",
  };

  const handleYes = async (data) => {
    const payload = {
      ...data,
      description: data.triggerEvent === "duringCall" ? data.description : "",
      actionFunction: "SMS",
    };
    try {
      const response = await customActionService.addSMSTrigger(
        projects[activeProject]._id,
        payload
      );

      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        onCancel();
        message.success("Trigger added");
        // setTabState("Settings")
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={addTriggerButtonInfo}
      onSubmit={handleYes}
      handleSecondary={onCancel}
      loading={loading}
      setLoading={setLoading}
      getValue={getPreviewDataInParent}
    />
  );
}

export default AddNewTrigger;
