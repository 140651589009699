import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import {
  useLocation,
  Outlet,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";

import authService from "../services/authService";

import { WebcallContext } from "../Context/WebcallContext";
import { useCompanyProjects } from "../Context/CompanyProjectsContext";

import { SidebarLayout } from "../common/sidebarLayout";
import { SidebarNavigation } from "../Home/Sidebar_navigation";

import { CreateNewWorkspace } from "./Workspaces/Create_new";

import { getSettings } from "V2.0/services/projectService";
import { manageSubscription } from "V2.0/service-worker/registerAndSubscribe";

function useSetting(projectId) {
  const [settings, setSettings] = useState({});

  const fetchSettings = async () => {
    try {
      const response = await getSettings(projectId);
      const data = response.data;
      setSettings(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchSettings(projectId);
    }
  }, [projectId]);

  return {
    settings,
    fetchSettings,
  };
}

export default function Home({}) {
  const location = useLocation();
  const navigate = useNavigate();

  const { company, projects, loading, error, firstNameShort, fetchProjects } =
    useCompanyProjects();
  const { cleanupResources } = useContext(WebcallContext);

  const [isOpen, setIsOpen] = useState(false);
  const [activeProject, setActiveProject] = useState(-1);
  const [projectId, setProjectId] = useState("");
  const [reload, setReload] = useState(false);

  const user = authService.getCurrentUser();
  const userId = user.userId;
  const { settings, fetchSettings } = useSetting(projectId);

  const [searchParams, setSearchParams] = useSearchParams();

  const projectsMap = useMemo(() => {
    if (projects.length > 0) {
      return projects.reduce((acc, project, index) => {
        return { ...acc, [index]: project._id };
      });
    }
  }, [projects]);

  useEffect(() => {
    if (projectId) {
      navigate(
        { pathname: location.pathname, search: `?projectId=${projectId}` },
        { replace: true }
      );
    }
  }, [projectId, location.pathname]);

  const updateActiveProject = (projectIndex) => {
    const projectId = projects[projectIndex]?._id;

    localStorage.setItem("activeProject", projectIndex);
    localStorage.setItem("lastActiveProjectId", projectId);

    setActiveProject(projectIndex);
    setProjectId(projectId);
  };

  useEffect(() => {
    if (!projectId || !user) {
      return;
    }

    manageSubscription(projectId, userId);
  }, [projectId, userId]);

  useEffect(() => {
    return () => {
      cleanupResources();
    };
  }, [location.pathname]);

  // Load the last known value of activeProject from localStorage on component mount
  useEffect(() => {
    const isProjectsAvailable = projects.length > 0;

    if (!isProjectsAvailable) {
      return;
    }

    const projectIdFromSearchParam = searchParams.get("projectId");

    if (projectIdFromSearchParam) {
      const projectIndex = projects.findIndex(
        (project) => project._id === projectIdFromSearchParam
      );
      if (projectIndex != -1) {
        updateActiveProject(projectIndex);
      } else {
        updateActiveProject(0);
      }

      return;
    }

    const activeProjectIndex = localStorage.getItem("activeProject");
    const lastActiveProjectId = localStorage.getItem("lastActiveProjectId");

    const isActiveProjectIndexEmpty = activeProjectIndex === null;

    const isActiveProjectIndexExistsInAvailableProjects =
      projects.length > Number(activeProjectIndex);

    if (
      isActiveProjectIndexEmpty ||
      !isActiveProjectIndexExistsInAvailableProjects
    ) {
      updateActiveProject(0);
      return;
    }

    if (isActiveProjectIndexExistsInAvailableProjects && lastActiveProjectId === null) {
      updateActiveProject(activeProjectIndex);
      return;
    }

    if (isActiveProjectIndexExistsInAvailableProjects && lastActiveProjectId) {
      const activeProjectIndexId = projectsMap[activeProjectIndex];
      if (activeProjectIndexId === lastActiveProjectId) {
        updateActiveProject(activeProjectIndex);
      } else {
        const projectsIndex = projects.findIndex(
          (project) => project._id === lastActiveProjectId
        );
        if (projectsIndex != -1) {
          updateActiveProject(projectsIndex);
        } else {
          updateActiveProject(0);
        }
      }
    }
  }, [projectsMap]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        if (error.response && error.response.status === 401) {
          navigate("/logout");
        } else if (
          error.response &&
          error.response.status === 400 &&
          !company
        ) {
          navigate("/welcome");
        }
      } else if (company) {
        if (company.accountType === "unsubscribed") {
          navigate("/billing/", {
            state: { company },
          });
        } else if (projects.length < 1) {
          setIsOpen(true);
        }
      }
    }
  }, [loading, error, company, projects]);

  return (
    <>
      <SidebarLayout
        sidebar={
          <SidebarNavigation
            setIsOpen={setIsOpen}
            projects={projects}
            activeProject={activeProject}
            setActiveProject={updateActiveProject}
            setProjectId={setProjectId}
            firstNameShort={firstNameShort}
            user={user}
          />
        }
      >
        <Outlet
          context={{
            projects,
            activeProject,
            company,
            projectId,
            reload,
            setReload,
            settings,
            fetchSettings,
            updateActiveProject,
            searchParams,
            setSearchParams,
            fetchProjects,
            setProjectId
          }}
        />
      </SidebarLayout>
      {isOpen && (
        <CreateNewWorkspace
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          updateActiveProject={updateActiveProject}
          setProjectId={setProjectId}
        />
      )}
    </>
  );
}
