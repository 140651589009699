import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../../components/Form";
import { message } from "antd";
import * as customActionService from "../../../services/customActions";
import { useFields } from "./models/useFields";

function EditSMSTrigger({
  tabState,
  projects,
  activeProject,
  settings,
  setTabState,
  item,
  setFormState,
  reload,
  setReload,
  onCancel,
}) {
  const [inputField, setInputField] = useState({
    actionName: item ? item.actionName : "",
    phoneNumber: item ? item.phoneNumber : "",
    description: item ? item.description : "",
    contentText: item ? item.contentText : "",
    triggerEvent: item ? item.triggerEvent : "duringCall",
  });

  const [previewData, setPreviewData] = useState(inputField);
  const { template, schema } = useFields(previewData);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const editTriggerButtonInfo = {
    buttons: "2",
    primaryLabel: "Save",
    secondaryLabel: "Cancel",
  };

  const handleYes = async (data) => {
    const payload = {
      ...data,
      description: data.triggerEvent === "duringCall" ? data.description : "",
      actionFunction: "SMS",
    };

    try {
      const response = await customActionService.updateCustomAction(
        projects[activeProject]._id,
        item._id,
        payload
      );

      if (response.status === 200) {
        setLoading(false);
        setReload(!reload);
        // setFormState("view")
        message.success("Trigger updated");
        onCancel();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Request failed");
      setLoading(false);
    }
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData("");
    }
    setPreviewData(dataDuplicate);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={editTriggerButtonInfo}
      onSubmit={handleYes}
      handleSecondary={onCancel}
      loading={loading}
      setLoading={setLoading}
      getValue={getPreviewDataInParent}
    />
  );
}

export default EditSMSTrigger;
