import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Avatar } from "V2.0/common/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "V2.0/common/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
} from "V2.0/common/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "V2.0/common/sidebarCatalyst";
import { StackedLayout } from "V2.0/common/stacked-layout";

import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  PlusIcon,
  UserIcon,
} from "@heroicons/react/16/solid";

import { useCompanyProjects } from "V2.0/Context/CompanyProjectsContext";
import ProfileTeam from "./ProfileTeam";
import authService from "V2.0/services/authService"; // Import authService
import WhiteLabel from "V2.0/NavBarComponents/WhiteLabel";
import { QuotaManager } from "./quotaManager";

const getNavItems = (company) => {
  const defaultNavItems = [{ label: "Team" }];

  if (company?.clientPortal) {
    defaultNavItems.push({ label: "Whitelabel" });
    console.log({ defaultNavItems });
  }

  if (company?.membershipManagement) {
    defaultNavItems.push({ label: "Quota Manager" });
  }

  return defaultNavItems;
};

function Account() {
  const { firstNameShort, company } = useCompanyProjects();

  const currentUser = authService.getCurrentUser(); // Get current user
  const navigate = useNavigate();
  const [activeState, setActiveState] = useState("Team");

  const navItems = getNavItems(company);

  if (!currentUser) {
    return <p>Loading</p>;
  }

  if (!currentUser?.isCompanyOwner) {
    return <p>You don't have access to this page</p>;
  }

  return (
    <StackedLayout
      navbar={
        <Navbar>
          <Dropdown>
            <DropdownButton
              as={NavbarItem}
              className="max-lg:hidden"
              onClick={() => navigate("/")}
            >
              <img src="/light.svg" />
            </DropdownButton>
          </Dropdown>
          <NavbarDivider className="max-lg:hidden" />
          <NavbarSection className="max-lg:hidden">
            {navItems.map(({ label, url }) => (
              <NavbarItem
                key={label}
                onClick={() => {
                  setActiveState(label); // Set active state on click
                }}
                current={activeState === label}
              >
                {label}
              </NavbarItem>
            ))}
          </NavbarSection>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar
                  initials={firstNameShort}
                  className="size-10 bg-zinc-900 text-white dark:bg-white dark:text-black"
                />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem to="/dashboard">
                  <UserIcon />
                  <DropdownLabel>Home</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem to="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar src={require("../images/logo.png")} />
                <SidebarLabel>Superdash</SidebarLabel>
                <ChevronDownIcon />
              </DropdownButton>
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              {navItems.map(({ label, url }) => (
                <SidebarItem key={label} current={activeState === label}>
                  {label}
                </SidebarItem>
              ))}
            </SidebarSection>
          </SidebarBody>
        </Sidebar>
      }
    >
      {activeState === "Team" && <ProfileTeam />}
      {company?.clientPortal && activeState === "Whitelabel" && <WhiteLabel />}
      {company?.membershipManagement && activeState === "Quota Manager" && (
        <QuotaManager />
      )}
    </StackedLayout>
  );
}

export default Account;
