import React, { useState, useEffect } from "react";

import { Checkbox, CheckboxField, CheckboxGroup } from "../../common/checkbox";
import { Description, Field, Label } from "../../common/fieldset";
import { Input } from "../../common/input";
import { Button } from "../../common/button";
import InputComponent from "../../components/InputComponent";

import * as projectService from "../../services/projectService";

import { message } from "antd";
import GHL from "../HomeIntegrations/GHL";
import { useOutletContext } from "react-router-dom";

function GhlCalendarSettings(props) {
  const { settings, thirdPartySettings, updateThirdPartySettings } = props;
  const [disabled, setDisabled] = useState(true);

  const [realTimeBookings, setRealTimeBookings] = useState({
    calendarSearchEnabled: false,
    daysToCheckSlotsFor: 2,
  });

  const [calendarSettings, setCalendarSettings] = useState({
    calendarId: "",
  });

  useEffect(() => {
    if (settings && settings.RAGSettings) {
      setRealTimeBookings({
        calendarSearchEnabled:
          settings?.RAGSettings.realTimeBookingSettings.calendarSearchEnabled,
        daysToCheckSlotsFor:
          settings?.RAGSettings.realTimeBookingSettings.daysToCheckSlotsFor,
      });
    }
  }, [settings]);

  useEffect(() => {
    if (thirdPartySettings) {
      setCalendarSettings(
        thirdPartySettings.calendarSettings.ghlCalendarSettings
      );
    }
  }, [thirdPartySettings]);

  const enableCalendarSearch = () => {
    setDisabled(false);
    setRealTimeBookings((prevState) => ({
      ...prevState,
      calendarSearchEnabled: !prevState.calendarSearchEnabled,
    }));
  };

  const onSave = async () => {
    try {
      message.loading("Updating calendar booking", 1);
      setDisabled(true);

      const response2 = await updateThirdPartySettings(
        {
          calendarSettings: {
            calendarProvider: "GHL",
            ghlCalendarSettings: calendarSettings,
            realTimeBookings,
          },
        },
        () => message.success("Calendar booking updated")
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="border rounded m-8 flex flex-col gap-y-8 p-8">
      <div className="">
        <CheckboxGroup>
          <CheckboxField>
            <Checkbox
              name="ghl_crm_search"
              onChange={enableCalendarSearch}
              value={realTimeBookings.calendarSearchEnabled}
              checked={realTimeBookings.calendarSearchEnabled}
            />
            <Label>Calendar bookings</Label>
            <Description>
              Turn on this feature to allow bookings in your GHL Calendar
            </Description>
          </CheckboxField>
        </CheckboxGroup>
      </div>
      <div className="">
        <Field>
          <Label>Days for slots</Label>
          <Description>
            Number of days to look for an available slot
          </Description>
          <Input
            type="number"
            min="2"
            max="7"
            value={realTimeBookings.daysToCheckSlotsFor}
            onChange={(e) => {
              setDisabled(false);
              setRealTimeBookings((prevState) => ({
                ...prevState,
                daysToCheckSlotsFor: Number(e.target.value),
              }));
            }}
          />
        </Field>
      </div>
      <div className="">
        <InputComponent
          type="text"
          label="Calendar Id"
          description='Paste your "Personal" or "event" calendar Id from your GHL Calendar'
          value={calendarSettings?.calendarId}
          onChange={(e) => {
            setDisabled(false);
            setCalendarSettings((prevState) => ({
              ...prevState,
              calendarId: e.target.value,
            }));
          }}
        />
      </div>
      <div className="self-end pb-4">
        <Button onClick={onSave} disabled={disabled}>
          Save
        </Button>
      </div>
    </div>
  );
}

function GhlContactsSearch(props) {
  const { settings, projectId, fetchSettings } = props;

  const [disabled, setDisabled] = useState(true);

  const [crmSearchEnabled, setCRMSearchEnabled] = useState(
    settings?.RAGSettings?.crmSearchEnabled
  );

  const handleSave = async () => {
    setDisabled(true);
    const data = {
      crmSearchEnabled: crmSearchEnabled,
    };
    try {
      message.loading("Updating search contacts");
      const response = await projectService.updateProject(projectId, {
        RAGSettings: data,
      });
      if (response.status === 200) {
        message.success("Search contacts updated");
        fetchSettings(projectId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGHLContactsSearch = () => {
    setCRMSearchEnabled(!crmSearchEnabled);
    setDisabled(false);
  };

  return (
    <div className="border m-8 rounded-lg">
      <div className="p-5">
        <CheckboxGroup>
          <CheckboxField>
            <Checkbox
              name="ghl_crm_search"
              onChange={handleGHLContactsSearch}
              value={crmSearchEnabled}
              checked={crmSearchEnabled}
            />
            <Label>Search Contacts</Label>
            <Description>
              Turn on this feature to automatically use the first name of
              individuals from your GHL CRM contacts on an inbound or outbound
              call. Currently available only for the voice app
            </Description>
          </CheckboxField>
        </CheckboxGroup>
      </div>
      <div className="px-4 py-4 sm:px-6 flex justify-end">
        <Button disabled={disabled} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}

function GhlCrmSettings(props) {
  const {
    projects,
    activeProject,
    settings,
    reload,
    setReload,
    thirdPartySettings,
    updateThirdPartySettings,
    projectId,
  } = props;

  return (
    <div className="p-8">
      <main className="">
        <div className="">
          <div className="">
            <div className="flex flex-col gap-y-2">
              <p className="font-semibold text-lg">GHL Contacts & Calendar</p>
              <p className="font-medium text-sm text-gray-500">
                Configure for contacts and scheduling calendar meetings
              </p>
            </div>
            <div className="py-10 lg:py-6">
              <div className="overflow-visible my-10">
                <GhlContactsSearch
                  settings={settings}
                  projects={projects}
                  activeProject={activeProject}
                  reload={reload}
                  setReload={setReload}
                  projectId={projectId}
                />
                <div className="pb-8">
                  <GhlCalendarSettings
                    settings={settings}
                    projects={projects}
                    projectId={projectId}
                    activeProject={activeProject}
                    thirdPartySettings={thirdPartySettings}
                    updateThirdPartySettings={updateThirdPartySettings}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

function GHLCRM() {
  const { thirdPartySettings, projectId, ...restProps } = useOutletContext();

  if (thirdPartySettings?.crmSettings?.crmProvider === "ghlCRM") {
    return (
      <GhlCrmSettings
        {...restProps}
        projectId={projectId}
        thirdPartySettings={thirdPartySettings}
      />
    );
  }

  return (
    <div>
      <main className="">
        <div className="">
          <div className="p-8">
            <div className="flex flex-col gap-y-2">
              <p className="font-semibold text-lg">GHL Contacts & Calendar</p>
              <p className="font-medium text-sm text-gray-500">
                Configure for contacts and scheduling calendar meetings
              </p>
            </div>
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 ">
              <div className="my-10">
                <div className="px-4 py-5 sm:px-6">
                  <GHL projectId={projectId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default GHLCRM;
