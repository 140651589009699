import React, { useState } from "react";

import * as campaignService from "../../services/campaignService";
import { message } from "antd";

import {
  Dialog,
  DialogBody,
  DialogTitle,
} from "../../common/dialog";

import { Call } from "./bulkUpload/Call";
import { Sms } from "./bulkUpload/SMS";
import { Whatsapp } from "./bulkUpload/Whatsapp";
import InputComponent from "V2.0/components/InputComponent";

export const CAMPAIGN_TYPE = {
  SMS: "SMS",
  CALL: "Call",
  WHATSAPP: "Whatsapp",
};

const campaignType = {
  [CAMPAIGN_TYPE.SMS]: (props) => <Sms {...props} />,
  [CAMPAIGN_TYPE.CALL]: (props) => <Call {...props} />,
  [CAMPAIGN_TYPE.WHATSAPP]: (props) => <Whatsapp {...props} />,
};

export function BulkContact(props) {
  const { isBulkUploadOpen, setIsBulkUploadOpen, projectId } = props;
  const [activeTab, setActiveTab] = useState(CAMPAIGN_TYPE.SMS);
  const [loading, setLoading] = useState(false);


  const onBulkUpload = async (data) => {
    setLoading(true);

    try {
      const response = await campaignService.bulkUpload(projectId, data);
      if (response.status === 201 || response.status === 200) {
        message.success("Success");
        setLoading(false);
        setIsBulkUploadOpen(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsBulkUploadOpen(false);
      message.error(error.response.data.msg);
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isBulkUploadOpen}
      onClose={() => setIsBulkUploadOpen(false)}
      size="lg"
    >
      <DialogTitle>Bulk Upload</DialogTitle>
      <DialogBody>
        <div className="flex flex-col gap-y-8">
          <InputComponent
            label="Campaign type"
            type="select"
            options={[
              { id: 1, label: CAMPAIGN_TYPE.SMS, value: CAMPAIGN_TYPE.SMS },
              { id: 2, label: CAMPAIGN_TYPE.CALL, value: CAMPAIGN_TYPE.CALL },
              {
                id: 2,
                label: CAMPAIGN_TYPE.WHATSAPP,
                value: CAMPAIGN_TYPE.WHATSAPP,
              },
            ]}
            value={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
          />
        </div>

        {campaignType[activeTab]({
          loading,
          onBulkUpload,
          onClose: () => setIsBulkUploadOpen(false),
          projectId,
          setLoading
        })}
      </DialogBody>
    </Dialog>
  );
}