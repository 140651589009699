import Joi from "joi-browser";
import { useState, useEffect } from "react";
import Form from "V2.0/components/Form";
import httpService from "V2.0/services/httpService";

const schema = {
  name: Joi.string().required().label("Name"),
  description: Joi.string().allow(null, "").label("Description"),
  message: Joi.string().required().label("Message"),
  template: Joi.object({
    templateName: Joi.string().required(),
    templateLanguage: Joi.string().required(),
  }),
};

export function WhatsAppSettings(props) {
  const { node, setNodes, closePanel, projectId } = props;
  const [state, setState] = useState({
    name: node?.data?.settings?.name || "",
    description: node?.data?.settins?.description || "",
    message: node?.data?.settings?.message || "",
    template: JSON.stringify({
      templateName: node?.data?.settings?.templateName,
      templateLanguage: node?.data?.settings?.templateLanguage,
    }),
    // voiceId: node?.data?.settings?.voiceId || "",
  });
  const [loading, setLoading] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);

  const fetchWhatsappMessageTemplates = async () => {
      try {
        const response = await httpService.get(
          `/whatsapp/manage/getTemplates/${projectId}`
        );
        const data = response.data;
        const templateNames = data.map((template) => ({
          templateName: template.name,
          templateLanguage: template.language,
        }));
        setMessageTemplates(templateNames);
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      fetchWhatsappMessageTemplates();
    }, []);

  function handleSave(data) {
    setNodes((nodes) => {
      const updatedNodes = nodes.map((_node) => {
        if (_node.id === node.id) {
          const { selected, ...rest } = _node;
          const { template, ...restData } = data;
          const { templateName, templateLanguage } = JSON.parse(template);
          return {
            ...rest,
            selected: false,
            data: {
              ...node.data,
              settings: {
                ...node.data.settings,
                ...restData,
                templateName,
                templateLanguage,
              },
            },
          };
        } else {
          return _node;
        }
      });
      return updatedNodes;
    });
    closePanel();
  }

  const template = [
    { name: "name", label: "Name *", type: "text" },
    {
      name: "description",
      label: "Description",
      type: "text",
    },
    {
      name: "template",
      label: "Message Template *",
      description: "Select one of the pre approved templates",
      type: "select",
      options: [
        { id: 0, value: JSON.stringify({}), label: "(No template selected)" },
      ].concat(
        messageTemplates.map((messageTemplate, index) => ({
          id: index,
          value: JSON.stringify(messageTemplate),
          label: messageTemplate.templateName,
        }))
      ),
    },
    {
      name: "message",
      label: "Message *",
      type: "textArea",
      style: { height: "100px" },
    },
  ];

  return (
    <div className="flex flex-col gap-y-4">
      <Form
        template={template}
        schema={schema}
        inputField={state}
        buttonInfo={{ label: "Save", style: "right-side" }}
        loading={loading}
        enableActionsOnChange={true}
        setLoading={setLoading}
        errorMessage={{}}
        onSubmit={handleSave}
      />
    </div>
  );
}
