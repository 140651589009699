import { Tooltip } from "antd";
import { clsx } from "clsx";

function StatsContainer({ children }) {
  return (
    <div className="mt-4 grid grid-cols-3 h-48 w-full gap-x-4">{children}</div>
  );
}

function StatsCardLoading(props) {
  return (
    <div className="h-[180px] flex flex-col gap-y-4 rounded-lg border p-4">
      <div className="text-md animate-pulse w-32 h-4 bg-gray-200 rounded" />
      <div className="flex flex-col gap-y-2">
        <div className="animate-pulse w-24 h-2 rounded bg-gray-200" />
        <div className="animate-pulse w-48 h-2 rounded bg-gray-200" />
      </div>
      <div className=" animate-pulse h-2 flex w-full rounded-lg bg-gray-200 hover:cursor-pointer"></div>
      <div className="flex justify-between">
        <div className="text-gray-500 text-sm" />
        <div className="text-gray-500 text-sm" />
      </div>
    </div>
  );
}

function StatsCard(props) {
  const { title, used, total, unit, calculateAvailable, calculateWidth } =
    props;

  const available = calculateAvailable(total, used);
  const width = calculateWidth(total, used);

  const isWidthGreaterThan75 = width > 75;
  const isWidthGreaterThan90 = width > 90;
  const widthClsx = clsx(
    `h-2 rounded-lg bg-green-500`,
    isWidthGreaterThan75 && "bg-orange-500",
    isWidthGreaterThan90 && "bg-red-500"
  );
  return (
    <div className="flex h-full flex-col col-span-1 gap-y-4 rounded-lg border p-4">
      <div className="text-md font-medium">
        <h3>{title}</h3>
      </div>
      <div>
        <p className="font-light text-sm text-gray-400">Available</p>
        <p className="text-md font-medium">{`${available} ${unit}`} </p>
      </div>
      <div className="h-2 flex w-full rounded-lg bg-gray-200 hover:cursor-pointer">
        <Tooltip title={`Used: ${used} ${unit}`} placement="rightBottom">
        <div className={widthClsx} style={{ width: `${width}%` }}></div>
        </Tooltip>
      </div>
      <div className="flex justify-between">
        <p className="text-gray-500 text-sm">0</p>
        <p className="text-gray-500 text-sm">{`Max: ${total} ${unit}`}</p>
      </div>
    </div>
  );
}

export { StatsContainer, StatsCard, StatsCardLoading };
