import { useEffect, useState } from "react";
import { IntegrationShell } from "../common/Shell";
import { useOutletContext } from "react-router-dom";
import Plivo from "V2.0/images/plivo.svg";
import {
  getthirdpartyData,
  thirdpartyUpdate,
} from "V2.0/services/thirdpartyService";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import { message } from "antd";

const buttonInfoPlivo = {
  label: "Connect Plivo",
  style: "entire-width",
};

const plivoFields = {
  authId: {
    template: {
      name: "authId",
      label: "Auth Id",
      type: "text",
    },
    schema: Joi.string().max(5000).required().label("Auth Id"),
  },
  authToken: {
    template: {
      name: "authToken",
      label: "Auth Token",
      type: "text",
    },
    schema: Joi.string().max(5000).required().label("Auth Token"),
  },
  plivoNumber: {
    template: {
      name: "plivoNumber",
      label: "Phone number (prefix country code)",
      type: "text",
    },
    schema: Joi.string()
      .regex(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
      .min(6)
      .required()
      .label("Phone number")
      .error((errors) => {
        return errors.map((err) => {
          if (err.type === "string.min") {
            return {
              message: "Phone number must be at least 6 characters long.",
            };
          }
          if (err.type === "string.regex.base") {
            return { message: "Please enter a valid phone number." };
          }
          if (err.type === "any.empty") {
            return { message: "Phone number is required." };
          }
          return err;
        });
      }),
  },
};

const plivoFieldTemplates = Object.values(plivoFields).map(
  (field) => field.template
);
const plivoFieldSchema = Object.keys(plivoFields).reduce(
  (schemaAcc, field) => ({ ...schemaAcc, [field]: plivoFields[field].schema }),
  {}
);

export function PlivoIntegration() {
  const { projectId } = useOutletContext();
  return (
    <IntegrationShell>
      <PlivoComponent projectId={projectId} />
    </IntegrationShell>
  );
}

function PlivoComponent(props) {
  const { projectId } = props;

  const [plivoFormFields, setPlivoFormFields] = useState({
    authId: "",
    authToken: "",
    plivoNumber: "",
  });

  const [errorMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchThirdPartySettings = async () => {
    try {
      const response = await getthirdpartyData(projectId);
      const { authId, authToken, plivoNumber } =
        response.data.telephonySettings.plivoSettings;

      plivoNumber &&
        setPlivoFormFields({
          authId,
          authToken,
          plivoNumber,
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchThirdPartySettings();
    }
  }, [projectId]);

  const handlePlivoConnect = async (data) => {
    let telephonySettings = {
      telephonySettings: {
        telephonyProvider: "plivo",
        plivoSettings: data,
      },
    };

    try {
      const response = await thirdpartyUpdate(telephonySettings, projectId);
      if (response.status === 200) {
        message.success("Plivo connected");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 500
      ) {
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md p-8 border rounded-lg">
          <div className="flex items-center gap-x-2 mb-4">
            <img src={Plivo} width={40} />
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Connect Plivo account
            </h3>
          </div>

          <div>
            <Form
              key={JSON.stringify(plivoFormFields)}
              errorMessage={errorMessage}
              inputField={plivoFormFields}
              template={plivoFieldTemplates}
              schema={plivoFieldSchema}
              buttonInfo={buttonInfoPlivo}
              onSubmit={handlePlivoConnect}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
          <div className="mt-6 text-sm text-gray-500">
          <p>
            Please find instructions to integrate Plivo{" "}
            <a
              href="https://docs.google.com/document/d/1Hg8JISrbqnZIFvdefaQN9sUrGVs9C1UHIBJHz-pE6eI"
              className="text-blue-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </div>
        </div>
        
      </div>
    </div>
  );
}
