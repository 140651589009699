import React, { useState, useEffect } from "react";
import { Button } from "../common/button";
import VoiceConfiguration from "./HomePrompt/VoiceConfiguration";
import LLMSettings from "./HomePrompt/LLMSettings";
import * as projectService from "../services/projectService";
import { message } from "antd";
import Form from "../components/Form";
import Joi from "joi-browser";
import DemoComponent from "../components/DemoComponent";
import TranscriptionSettings from "./HomePrompt/TranscriptionSettings";
// import WelcomeMessage from "./HomePrompt/WelcomeMessage";
import { WelcomeMessage } from "./HomePrompt/welcomeMessage/index";
import Webcall from "./HomePrompt/Webcall";
import VoiceSettings from "./HomePrompt/VoiceSettings";
import * as thirdPartyServices from "V2.0/services/thirdpartyService";
import TestPhoneCall from "./HomePrompt/TestPhoneCall";
import { useOutletContext } from "react-router-dom";
import { Heading } from "V2.0/common/heading";
import {
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
  PencilIcon,
  PhoneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import TabsWithBadge from "V2.0/common/tabsWithBadge";

import { InactivitySettings } from "./HomePrompt/Settings/Inactivity";
import { InterruptionSettings } from "./HomePrompt/Settings/Interruption";

const CALLTABS = {
  PHONE_CALL: "Call your Phone",
  // WEB_CALL: "Web Call",
};

const LLMModels = {
  "gpt-4o-mini": "GPT 4o mini",
  "superdash-35-turbo": "GPT 3.5 Turbo",
  "gemini-1.5-flash": "Gemini 1.5 Flash",
  "groq/llama3-8b-8192": "LLaMA3 8b",
  "groq/llama3-70b-8192": "LLaMA3 70b",
  "groq/mixtral-8x7b-32768": "Mixtral 8x7b",
};

function SpeakWithAI(props) {
  const { projects, activeProject, settings } = props;
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(CALLTABS.PHONE_CALL);

  const tabs = Object.values(CALLTABS).map((tab) => ({
    name: tab,
    current: activeTab === tab,
    href: "#",
  }));

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PhoneIcon />
        Speak with AI
      </Button>
      {open && (
        <Dialog open={open} onClose={() => {}}>
          <DialogTitle>
            <div className="flex justify-between">
              {/* <TabsWithBadge
                className="border-b"
                tabs={tabs}
                onTabChange={(e, { tab, index }) => {
                  setActiveTab(tab.name);
                }}
              /> */}
              Call Phone
              <Button plain onClick={() => setOpen(false)}>
                <XMarkIcon />
              </Button>
            </div>
          </DialogTitle>
          <DialogBody>
            {/* {activeTab === CALLTABS.WEB_CALL && (
              <div className="flex flex-col min-h-[200px] justify-center items-center">
                <div>
                  <Webcall
                    projectID={projects[activeProject]._id}
                    settings={settings}
                  />
                </div>
              </div>
            )} */}
            {/* {activeTab === CALLTABS.PHONE_CALL && ( */}
              <TestPhoneCall
                projects={projects}
                activeProject={activeProject}
                settings={settings}
              />
            {/* )} */}
          </DialogBody>
        </Dialog>
      )}
    </>
  );
}

function Language(props) {
  const { projects, activeProject, settings } = props;
  const [open, setOpen] = useState(false);
  const [inputField, setInputField] = useState({
    transcriptionLanguage: "",
  });

  const languages = {
    bg: { label: "Bulgarian" },
    ca: { label: "Catalan" },
    cs: { label: "Czech" },
    da: { label: "Danish" },
    "da-DK": { label: "Danish (Denmark)" },
    nl: { label: "Dutch" },
    en: { label: "English" },
    "en-US": { label: "English (United States)" },
    "en-AU": { label: "English (Australia)" },
    "en-GB": { label: "English (United Kingdom)" },
    "en-NZ": { label: "English (New Zealand)" },
    "en-IN": { label: "English (India)" },
    et: { label: "Estonian" },
    fi: { label: "Finnish" },
    "nl-BE": { label: "Flemish" },
    fr: { label: "French" },
    "fr-CA": { label: "French (Canada)" },
    de: { label: "German" },
    "de-CH": { label: "German (Switzerland)" },
    el: { label: "Greek" },
    hi: { label: "Hindi" },
    hu: { label: "Hungarian" },
    id: { label: "Indonesian" },
    it: { label: "Italian" },
    ja: { label: "Japanese" },
    ko: { label: "Korean" },
    "ko-KR": { label: "Korean (South Korea)" },
    lv: { label: "Latvian" },
    lt: { label: "Lithuanian" },
    ms: { label: "Malay" },
    no: { label: "Norwegian" },
    pl: { label: "Polish" },
    pt: { label: "Portuguese" },
    "pt-BR": { label: "Portuguese (Brazil)" },
    ro: { label: "Romanian" },
    ru: { label: "Russian" },
    sk: { label: "Slovak" },
    es: { label: "Spanish" },
    "es-419": { label: "Spanish (Latin America)" },
    sv: { label: "Swedish" },
    "sv-SE": { label: "Swedish (Sweden)" },
    th: { label: "Thai" },
    "th-TH": { label: "Thai (Thailand)" },
    tr: { label: "Turkish" },
    uk: { label: "Ukrainian" },
    vi: { label: "Vietnamese" },
    "zh-CN": { label: "Chinese (Mandarin, Simplified)" },
    "zh-TW": { label: "Chinese (Mandarin, Traditional)" },
    mutli: { label: "Multilingual (Spanish/English)" },
  };

  const fetchData = async () => {
    try {
      if (projects[activeProject]._id) {
        const response = await thirdPartyServices.getthirdpartyData(
          projects[activeProject]._id
        );

        const language =
          response.data.transcriptionSettings.deepgramSettings
            .transcriptionLanguage;

        if (languages[language]) {
          setInputField({
            transcriptionLanguage: language,
          });
        } else {
          setInputField({ transcriptionLanguage: "en-US" });
        }
      }
    } catch (error) {
      // message.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    if (projects[activeProject]._id) {
      fetchData();
    }
  }, [projects, activeProject]);

  const languageLabel = languages[inputField.transcriptionLanguage]?.label;

  return (
    <div className="flex items-center border rounded-lg">
      <div className="px-2 border-r">
        <p className="text-sm">{inputField && languageLabel}</p>
      </div>
      <Button className="rounded-none" plain onClick={() => setOpen(true)}>
        <PencilIcon />
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={() => {
            fetchData();
            setOpen(false);
          }}
        >
          <DialogTitle>
            <div className="flex justify-between items-center">
              <div>
                <p>Edit Language</p>
              </div>
              <Button
                plain
                onClick={() => {
                  fetchData();
                  setOpen(false);
                }}
              >
                <XMarkIcon />
              </Button>
            </div>
          </DialogTitle>
          <DialogBody>
            <TranscriptionSettings
              projects={projects}
              activeProject={activeProject}
              language={inputField}
              fetchThirdPartySettings={fetchData}
            />
          </DialogBody>
        </Dialog>
      )}
    </div>
  );
}

function LLM(props) {
  const { projects, activeProject, settings, fetchSettings } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center border rounded-lg">
      <div className="px-2 border-r">
        <p className="text-sm">
          {LLMModels[settings?.gptResponseModel] ?? "GPT 4o mini"}
        </p>
      </div>
      <Button plain onClick={() => setOpen(true)}>
        <PencilIcon />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <div>
              <p>Edit Model</p>
            </div>
            <Button plain onClick={() => setOpen(false)}>
              <XMarkIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogBody>
          <LLMSettings
            projects={projects}
            activeProject={activeProject}
            settings={settings}
            fetchSettings={fetchSettings}
          />
        </DialogBody>
      </Dialog>
    </div>
  );
}

function Voice(props) {
  const { projects, activeProject, settings } = props;
  const [open, setOpen] = useState(false);
  const [inputField, setInputField] = useState({
    voiceId: "",
  });

  const fetchData = async () => {
    try {
      if (projects[activeProject]._id) {
        const response = await thirdPartyServices.getthirdpartyData(
          projects[activeProject]._id
        );

        const voiceId = response.data.ttsSettings.voiceId;

        if (voices[voiceId]) {
          setInputField({ voiceId });
        } else {
          setInputField({ voiceId: "Eva" });
        }
      }
    } catch (error) {
      // message.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [projects, activeProject]);

  const voices = {
    Eva: { label: "Eva (Female, English US)" },
    Cindy: { label: "Cindy (Female,English)" },
    Arthor: { label: "Arthor (English US, Male)" },
    Antoine: { label: "Antoine (Female, French)" },
    Andy: { label: "Andy (Male, English US)" },
    Liz: { label: "Liz (Female, English US)" },
    Brendan: { label: "Brendan (Male, English US)" },
    Sonia: { label: "Sonia (Female, English UK)" },
    Max: { label: "Max (Male, German)" },
    Felix: { label: "Felix (Male, German)" },
    Aurora: { label: "Aurora (Female, Italian)" },
    Marco: { label: "Marco (Male, Italian)" },
    Giovanni: { label: "Giovanni (Male, Italian)" },
    Martina: { label: "Martina (Female, Italian)" },
    Leonardo: { label: "Leonardo (Male, Italian)" },
    Sofia: { label: "Sofia (Female, Italian)" },
    Nat: { label: "Nat (Male, English AU)" },
    Ann: { label: "Ann (Female, English AU)" },
    Jo: { label: "Jo (Female, English AU)" },
    Will: { label: "Will (Male, English AU)" },
    Elise: { label: "Elise (Female, English AU)" },
    Dunn: { label: "Dunn (Male, English AU)" },
    Sharma: { label: "Sharma (Male, Hindi)" },
    Shantanu: { label: "Shantanu (Male, Hindi)" },
    Vikram: { label: "Vikram (Male, Hindi - Devanagri Script Only)" },
    Kavya: { label: "Kavya (Female, Hindi)" },
    Sindhu: { label: "Sindhu (Female, Hindi)" },
    Neha: { label: "Neha (Female, Hindi)" },
    Afonso: { label: "Afonso (Male, Portuguese)" },
    Artur: { label: "Artur (Male, Brazilian Portuguese)" },
    Evora: { label: "Evora (Female, Brazilian Portuguese)" },
    Cassandra: { label: "Cassandra (Female, Brazilian Portuguese)" },
    Lisa: { label: "Lisa (Female, English US)" },
    Cart: { label: "Cart (Male, English US)" },
    Kyle: { label: "Kyle (Male, English US)" },
    Charlotte: { label: "Charlotte (Female, French)" },
    Alain: { label: "Alain (Male, French)" },
    Juliette: { label: "Juliette (Female, French)" },
    Louis: { label: "Louis (Male, French)" },
    Charles: { label: "Charles (Male, French)" },
    Lucia: { label: "Lucia (Female, Spanish)" },
    Diego: { label: "Diego (Male, Spanish)" },
    Maria: { label: "Maria (Female, Spanish)" },
    Jose: { label: "Jose (Male, Spanish)" },
    Juan: { label: "Juan (Male, Spanish)" },
    Felipe: { label: "Felipe (Male, Spanish)" },
    Fernanda: { label: "Fernanda (Female, Spanish)" },
    Juan: { label: "Juan (Male, Argentinian Spanish)" },
    Ines: { label: "Ines (Female, Argentinian Spanish)" },
    Sophia: { label: "Sophia (Female, English US)" },
    Ignacio: { label: "Ignacio (Male, English US)" },
    Britt: { label: "Britt (Female, English)" },
    Marcus: { label: "Marcus (Male, English)" },
    Cynthia: { label: "Cynthia (Female, English)" },
    Cath: { label: "Cath (Female, English)" },
    Nick: { label: "Nick (Male, English)" },
    Jean: { label: "Jean (Male, French)" },
    Madeleine: { label: "Madeleine (Female, French)" },
    Joelle: { label: "Joelle (Female, French)" },
    Camille: { label: "Camille (Female, French)" },
    Celine: { label: "Celine (Female, French)" },
    Jacques: { label: "Jacques (Female, French)" },
    Jan: { label: "Jan Schevenels (Male, Flemish)" },
    Petra: { label: "Petra (Female, Flemish)" },
    HansC: { label: "Hans (Male, Flemish)" },
    Ben: { label: "Ben van Praag (Male, Flemish)" },
    Christian: { label: "Christian - Nieuws Stijl (Male, Flemish)" },
    Walter: { label: "Walter (Male, Flemish)" },
    Bart: { label: "Bart(Male, Flemish)" },
    Adrien: { label: "Adrien (Male, French(Belgie))" },
    Christophe: { label: "Christophe (Male, French(Belgie))" },
  };

  const voice = voices[inputField.voiceId]?.label;

  return (
    <div className="flex items-center border rounded-lg">
      <div className="px-2 border-r">
        <p className="text-sm">{inputField && voice}</p>
      </div>
      <Button plain onClick={() => setOpen(true)}>
        <PencilIcon />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <div>
              <p>Edit Model</p>
            </div>
            <Button plain onClick={() => setOpen(false)}>
              <XMarkIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogBody>
          <VoiceSettings
            projects={projects}
            activeProject={activeProject}
            voice={inputField}
            fetchThirdPartySettings={fetchData}
          />
        </DialogBody>
      </Dialog>
    </div>
  );
}

function Inactivity(props) {
  const { projectId, settings, fetchSettings } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="flex items-center gap-x-2 px-8 border border-gray-300 rounded-lg w-64 h-12 cursor-pointer hover:bg-gray-100"
      >
        <Cog6ToothIcon className="size-5 text-gray-400" />
        <span className="text-sm/6">Inactivity Settings</span>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <div>
              <p>Inactivity Settings</p>
            </div>
            <Button plain onClick={() => setOpen(false)}>
              <XMarkIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogBody>
          <InactivitySettings
            settings={settings}
            projectId={projectId}
            fetchSettings={fetchSettings}
            onClose={() => setOpen(false)}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

function Interruption(props) {
  const { projectId, settings, fetchSettings } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="flex gap-x-2 items-center px-8 border border-gray-300 rounded-lg w-64 h-12 cursor-pointer hover:bg-gray-100"
      >
        <Cog6ToothIcon className="size-5 text-gray-400" />
        <span className="text-sm/6">Interruption Settings</span>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className="flex justify-between items-center">
            <div>
              <p>Voice Agent Interruption Settings</p>
            </div>
            <Button plain onClick={() => setOpen(false)}>
              <XMarkIcon />
            </Button>
          </div>
        </DialogTitle>
        <DialogBody>
          <InterruptionSettings
            settings={settings}
            projectId={projectId}
            fetchSettings={fetchSettings}
            onClose={() => setOpen(false)}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

function HomePrompt() {
  const {
    projects,
    activeProject,
    setReload,
    reload,
    projectId,
    settings,
    fetchSettings,
  } = useOutletContext();

  const [inputField, setInputField] = useState({ rawPrompt: "" });
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState("loading");
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "rawPrompt",
      label: "Prompt",
      type: "textArea",
      style: { height: "350px" },
    },
  ];

  const buttonInfo = {
    label: "Save Prompt",
    style: "right-side",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    rawPrompt: Joi.string().max(25000).required().label("Raw prompt"),
  };

  useEffect(() => {
    const fetchData = async () => {
      setFormState("loading");
      try {
        if (projects[activeProject]?._id) {
          const response = await projectService.getSettings(
            projects[activeProject]._id
          );

          setInputField({
            rawPrompt: response.data.rawPrompt.replace(
              /Context:([\s\S]*)$/,
              "" || ""
            ),
          });
          setFormState("form");
        }
      } catch (error) {
        // message.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [projects, reload, activeProject]);

  const handleCustomPrompt = async (data) => {
    setLoading(true);
    const response = await projectService.updateProject(
      projects[activeProject]._id,
      data
    );
    if (response.status === 200) {
      message.success("Changes saved");
      fetchSettings();
      setLoading(false);
    }
  };

  if (formState === "loading") {
    return (
      <div className="flex h-screen">
        {/* Flex container for the entire view */}
        <main className="flex-grow flex flex-col m-5">
          <div className="relative divide-y divide-gray-200 overflow-visible rounded-lg shadow my-2 animate-pulse">
            <div className="px-4 py-5 sm:px-6 rounded">Prompt</div>
            <div className="px-4 py-5 sm:p-6 bg-white rounded">
              {/* Placeholder for the form component */}
              <div className="space-y-4">
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Placeholder for an input field */}
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Another placeholder for an input field */}
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Another placeholder for an input field */}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else
    return (
      <div className="flex flex-col h-[calc(100vh-16px)] rounded-lg">
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 dark:border-white/10 py-2 px-4">
          <Heading>Prompt</Heading>
        </div>
        <div className="w-full h-full overflow-auto">
          <div className="w-4/5 px-4 flex flex-col gap-y-2 mx-auto my-0 pt-8">
            <div className="flex justify-between items-center">
              <div className="flex gap-x-2">
                <Voice
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                />

                <LLM
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                  fetchSettings={fetchSettings}
                />
                <Language
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                />
              </div>
              <div className="flex gap-x-2">
                <SpeakWithAI
                  projects={projects}
                  activeProject={activeProject}
                  settings={settings}
                />
                <Button outline onClick={() => setSideBarOpen(true)}>
                  <ChatBubbleBottomCenterTextIcon /> Chat with AI
                </Button>
              </div>
            </div>
            <div>
              {/* <WelcomeMessage
                projects={projects}
                activeProject={activeProject}
                settings={settings}
                fetchSettings={fetchSettings}
                
              /> */}
              <WelcomeMessage
                projectId={projectId}
                settings={settings}
                fetchSettings={fetchSettings}
              />
            </div>
            <div className="">
              <Form
                key={JSON.stringify(inputField)}
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={buttonInfo}
                onSubmit={handleCustomPrompt}
                loading={loading}
                setLoading={setLoading}
                showRevert={true}
                enableActionsOnChange={true}
              />
            </div>
            <div>
              <VoiceConfiguration
                projects={projects}
                activeProject={activeProject}
                settings={settings}
              />
            </div>
            <div>
              <p className="text-sm/6 text-zinc-950 font-medium">
                Agent Behaviour Settings
              </p>
              <div className="flex gap-x-4 my-2">
                <Inactivity
                  settings={settings}
                  projectId={projectId}
                  fetchSettings={fetchSettings}
                />
                <Interruption
                  settings={settings}
                  projectId={projectId}
                  fetchSettings={fetchSettings}
                />
              </div>
            </div>
          </div>
        </div>
        <DemoComponent
          projects={projects}
          activeProject={activeProject}
          open={sideBarOpen}
          setOpen={setSideBarOpen}
          SidebarTitle="Chat with AI"
        />
      </div>
    );
}

export default HomePrompt;
