import React, { useState } from "react";

import { Checkbox, CheckboxField } from "../../../common/checkbox";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogBody,
  DialogDescription,
} from "../../../common/dialog";
import { Button } from "../../../common/button";
import { Label } from "../../../common/fieldset";

import { message } from "antd";

import { deleteProject } from "../../../services/projectService";
import { useCompanyProjects } from "../../../Context/CompanyProjectsContext";
import { useNavigate, useLocation } from "react-router-dom";

function useCheckbox() {
  const [isChecked, setIsChecked] = useState(false);

  const Field = ({ label }) => (
    <CheckboxField>
      <Checkbox
        onChange={() => setIsChecked((prevState) => !prevState)}
        checked={isChecked}
      />
      <Label>{label}</Label>
    </CheckboxField>
  );

  return {
    isChecked,
    Field,
  };
}

export function DeleteWorkspace(props) {
  const { isOpen, onClose, project } = props;
  const { isChecked, Field: CheckBox } = useCheckbox();
  const [loading, setLoading] = useState(false);
  const { fetchProjects } = useCompanyProjects();
  const location = useLocation();
  const navigate = useNavigate();

  async function handleDeleteWorkspace() {
    setLoading(true);
    try {
      message.loading("Deleting your workspace");
      const response = await deleteProject({ projectId: project._id });
      if (response.status === 200) {
        message.success("Workspace deleted");
        fetchProjects();
      }
    } catch (error) {
      if (error.response.status === 404) {
        message.warning("Workspace doesn't exist. Refresh the page");
      }
      console.error(error);
    }
    setLoading(false);
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Delete {project.name} workspace?</DialogTitle>
      <DialogDescription>
        Do you want to delete <span className="font-semibold text-gray-900">{project.name}</span> workspace and all of its data?
      </DialogDescription>
      <DialogBody>
        <CheckBox label="Confirm deletion" />
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleDeleteWorkspace} disabled={!isChecked} loading={loading}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
