import { clsx } from "clsx";

export function Chip(props) {
  const { label, onClick, isActive } = props;

  const chipClasses = clsx(
    "inline-flex cursor-pointer items-center rounded-full px-4 py-2 text-xs font-semibold text-gray-500 bg-gray-200",
    isActive && "bg-green-200 text-green-800",
    !isActive && "hover:bg-gray-300"
    
  );

  return (
    <div className={chipClasses} onClick={onClick}>
      {label}
    </div>
  );
}
