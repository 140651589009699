import React, { useState } from "react";
import { Button } from "V2.0/common/button";

import { IntegrationShell } from "../common/Shell";

function MakeComponent() {
  const openMakeInNewTab = () => {
    window.open(
      "https://www.make.com/en/hq/app-invitation/5100dd08075e265bd2b196ab829403ad/",
      "_blank"
    );
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 border rounded-lg">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Accept Make invite
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Automate your workflows using Superdash and Make
              <Button className="mt-5" onClick={openMakeInNewTab}>
                Connect Make
              </Button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function MakeIntegration() {
  return (
    <IntegrationShell>
      <MakeComponent />
    </IntegrationShell>
  );
}
