import { Handle, Position, useNodesData, useReactFlow } from "@xyflow/react";
import { useEffect } from "react";
import {
  ChatBubbleBottomCenterIcon,
  ClockIcon,
  Cog6ToothIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/16/solid";
import { useWorkflowBuilder } from "../index2";
import { Popover, Tooltip } from "antd";
import { Badge } from "V2.0/common/badge";
import { FaWhatsapp } from "react-icons/fa";

const AddContent = (props) => {
  const { node } = props;
  const { openPanel } = useWorkflowBuilder();

  const isStartNode = node.data.isStartNode;
  return (
    <div className="flex flex-col gap-y-2">
      <div
        onClick={(e) => {
          e.stopPropagation();
          openPanel({ mode: "addPath", data: node });
        }}
        className="text-sm cursor-pointer hover:bg-gray-100 p-2"
      >
        Add Step
      </div>
      {/* {!isStartNode && (
        <div className="text-sm cursor-pointer hover:bg-gray-100 p-2">
          Add Step above
        </div>
      )} */}
      {/* <div className="text-sm cursor-pointer hover:bg-gray-100 p-2">
        Add Step below
      </div> */}
    </div>
  );
};

const Icons = {
  delay: () => <ClockIcon className="size-4 text-gray-500" />,
  communication: (communicationType) => CommunicationIcons[communicationType],
};

const CommunicationIcons = {
  whatsapp: <FaWhatsapp className="size-4 text-gray-500" />,
  call: <PhoneIcon className="size-4 text-gray-500" />,
  sms: <ChatBubbleBottomCenterIcon className="size-4 text-gray-500" />,
};

const Y = Icons["delay"];
const Z = Icons["communication"];

export const CustomNode = ({ id }) => {
  const internalNode = useNodesData(id);
  const { openPanel, isPanelOpen } = useWorkflowBuilder();
  const { getNode } = useReactFlow();
  const node = getNode(id);
  const { data } = internalNode;

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div
        className={`cursor-pointer w-[300px] h-[90px] bg-white p-4 rounded-lg shadow-md border
            border-2 "border-blue-500 flex flex-col justify-between`}
      >
        <div className="flex justify-between items-center">
          <Tooltip title={data?.settings?.name}>
            <div className="flex gap-x-2 items-center">
              {Icons[data?.nodeType](data.communicationType)}
              <span className="text-sm font-medium truncate">
                {data?.settings?.name || "unnamed action"}
              </span>
            </div>
          </Tooltip>

          <div className="flex space-x-2">
            <Popover
              content={() => <AddContent node={node} />}
              trigger="click"
              arrow={false}
              placement="right"
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();

                  // onDelete(id);
                }}
                disabled={isPanelOpen}
                className={`bg-none border-none cursor-pointer text-lg hover:bg-gray-200 rounded p-1 disabled:cursor-not-allowed`}
              >
                <PlusIcon className="size-4 text-gray-600" />
              </button>
            </Popover>
            <button
              onClick={(e) => {
                e.stopPropagation();
                data.onDelete();
              }}
              disabled={isPanelOpen}
              className={`bg-none border-none cursor-pointer text-lg hover:bg-gray-200 rounded p-1 disabled:cursor-not-allowed`}
            >
              <TrashIcon className="size-4 text-gray-600" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                openPanel({ mode: "Configure", data: node });
              }}
              className={`bg-none border-none cursor-pointer text-lg hover:bg-gray-200 rounded p-1 disabled:cursor-not-allowed`}
            >
              <Cog6ToothIcon className="size-4 text-gray-600" />
            </button>
          </div>
        </div>
        {data?.settings?.description && (
          <Tooltip title={data?.settings?.description} placement="bottom">
            <p className="text-xs text-gray-500 truncate">
              {data?.settings?.description}
            </p>
          </Tooltip>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
};
