import Joi from "joi-browser";
import { useState, useEffect } from "react";
import Form from "V2.0/components/Form";

const template = [
  { name: "name", label: "Name *", type: "text" },
  {
    name: "description",
    label: "Description",
    type: "text",
  },
  {
    name: "fromNumber",
    label: "From Number",
    description: "Leave blank to use default number",
    type: "text",
  },
  {
    name: "message",
    label: "Message",
    type: "textArea",
    style: { height: "100px" },
  },
  // {name: "voiceId", label: "Voice Id", type: "text"}
];

const schema = {
  name: Joi.string().required().label("Name"),
  description: Joi.string().allow(null, "").label("Description"),
  message: Joi.string().allow(null, "").label("Message"),
  fromNumber: Joi.string()
    .regex(/^\+?[0-9]+$/)
    .allow(null, "")
    .label("From Number"),
  // voiceId: Joi.string().allow(null, "").label("Voice Id")
};

export function CallSettings(props) {
  const { node, setNodes, closePanel } = props;
  const [state, setState] = useState({
    name: node?.data?.settings?.name || "",
    description: node?.data?.settins?.description || "",
    message: node?.data?.settings?.message || "",
    // voiceId: node?.data?.settings?.voiceId || "",
  });
  const [loading, setLoading] = useState(false);

  function updateState(value) {
    setState((prevState) => ({
      ...prevState,
      ...value,
    }));
  }

  function handleSave(data) {
    setNodes((nodes) => {
      const updatedNodes = nodes.map((_node) => {
        if (_node.id === node.id) {
          const { selected, ...rest } = _node;
          return {
            ...rest,
            selected: false,
            data: {
              ...node.data,
              settings: { ...node.data.settings, ...data },
            },
          };
        } else {
          return _node;
        }
      });
      return updatedNodes;
    });
    closePanel();
  }

  return (
    <div className="flex flex-col gap-y-4">
      <Form
        template={template}
        schema={schema}
        inputField={state}
        buttonInfo={{ label: "Save", style: "right-side" }}
        loading={loading}
        setLoading={setLoading}
        errorMessage={{}}
        onSubmit={handleSave}
      />
    </div>
  );
}
