import Joi  from "joi-browser";
import React from "react";
import { Link } from "V2.0/common/link";

export const sheetURLFieldTemplate = {
  name: "sheetURL",
  label: "Google Sheet Link",
  description: `Add superdash@superdash-382709.iam.gserviceaccount.com as 'Editor'.`,
  type: "text",
  info: <p className="text-sm text-gray-500">Please use this <Link className="text-blue-600 underline" href="https://docs.google.com/spreadsheets/d/1qsjNxme6pMaRTzFjzDZwiUM_a-ArBTFcAVT0xMCmVoM/edit?usp=sharing">contacts template</Link> for referrence.</p>
};

export const sheetURLFieldSchema = {
  sheetURL: Joi.string().uri().required().label("sheetURL"),
};
