import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

import httpService from "V2.0/services/httpService";
import authService from "V2.0/services/authService";

import { CONVERSATION } from "../../models/Conversation";
import { ConversationMoreOptions } from "../conversation_more_options";

import { ChatEditor } from "V2.0/HomePages/Threads";
import { Button } from "V2.0/common/button";

import { AgentAssign, AssignmentMode } from "../agent_reassignment";
import { ResolveConversation } from "../conversation_resolve";
import { ConversationSourceIcon, SourceIcon } from "../conversation_item";
import { checkForMediaMessage, MediaMessages } from "./Whatsapp";

export function formatTimestamp(timestamp) {
  const now = new Date();
  const date = new Date(timestamp);

  const timeDiff = now - date;

  const formattedDate = date.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return formattedDate;
}

export function formatTimestampBasedOnTimezone(timestamp, timeZone) {
  const date = new Date(timestamp);

  const formattedDate = new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone,
  }).format(date);

  return formattedDate;
}

export const useConversationById = (conversationData) => {
  const [conversation, setConversation] = useState(conversationData);

  const fetchConversationById = async (projectId, conversationId) => {
    try {
      const response = await httpService.get(
        `/conversation/getConversation/${projectId}/${conversationId}`
      );
      if (response.status === 200) {
        setConversation(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    conversation,
    setConversation,
    fetchConversationById,
  };
};

export const ConversationMessage = {
  [CONVERSATION.EMBEDDED_CHAT]: (props) => (
    <ConversationMessagesView {...props} />
  ),
  [CONVERSATION.INBOUND_VOICE]: (props) => (
    <ConversationMessagesView {...props} />
  ),
  [CONVERSATION.OUTBOUND_VOICE]: (props) => (
    <ConversationMessagesView {...props} />
  ),
  [CONVERSATION.SMS]: (props) => <ConversationMessagesView {...props} />,
  [CONVERSATION.WEB_CALL]: (props) => <ConversationMessagesView {...props} />,
  [CONVERSATION.WHATSAPP]: (props) => <WhatsappMessagesView {...props} />,
};

function useWhatsappSelected(whatsAppConversation, user) {
  const { status, humanAgentAssignmentSettings, messages } =
    whatsAppConversation;

  const lastMessage = messages[messages.length - 1];
  const lastMessageTimestamp =
    lastMessage?.aTimestamp || lastMessage?.qTimestamp;
  const is24HoursElapsedSinceLastMessage =
    lastMessageTimestamp > new Date().getTime();

  const isHumanAgentAssigned =
    !!humanAgentAssignmentSettings?.humanAgentAssigned;

  const isActiveUserTheHumanAgent =
    isHumanAgentAssigned &&
    humanAgentAssignmentSettings.humanAgentAssigned?.email === user.email;

  const isConversationActive = status === 0;

  const isActiveUserCompanyOwner = user?.isCompanyOwner;

  const allowActiveUserToSendMessage =
    isHumanAgentAssigned &&
    isActiveUserTheHumanAgent &&
    isConversationActive &&
    !is24HoursElapsedSinceLastMessage;

  const allowActiveUserToReassign =
    isHumanAgentAssigned &&
    (isActiveUserTheHumanAgent || isActiveUserCompanyOwner) &&
    isConversationActive;

  const allowActiveUserToResolve =
    isHumanAgentAssigned &&
    (isActiveUserTheHumanAgent || isActiveUserCompanyOwner) &&
    isConversationActive;

  const allowActiveUserToAssign = !isHumanAgentAssigned && isConversationActive;

  const allowActiveUserToStartWithATemplate =
    isHumanAgentAssigned &&
    isActiveUserTheHumanAgent &&
    isConversationActive &&
    is24HoursElapsedSinceLastMessage;

  return {
    allowActiveUserToSendMessage,
    allowActiveUserToReassign,
    allowActiveUserToResolve,
    allowActiveUserToAssign,
    allowActiveUserToStartWithATemplate,
  };
}

function WhatsappMessagesView(props) {
  const {
    selectedConversation,
    onDeleteConversation,
    onRetryExtraction,
    onViewContactClick,
    fetchConversations,
    resetSelectedConversation,
    timeZone
  } = props;

  const user = authService.getCurrentUser();
  const [whatsappConversation, setWhatsappConversation] = useState(
    selectedConversation || {}
  );
  const [assignAgent, setAssignAgent] = useState({
    isOpen: false,
    mode: "",
  });

  const {
    allowActiveUserToAssign,
    allowActiveUserToReassign,
    allowActiveUserToResolve,
    allowActiveUserToSendMessage,
    allowActiveUserToStartWithATemplate,
  } = useWhatsappSelected(selectedConversation, user);

  const fetchWhatsappConversationById = async (projectId, conversationId) => {
    try {
      const response = await httpService.get(
        `/conversation/getConversation/${projectId}/${conversationId}`
      );
      if (response.status === 200) {
        setWhatsappConversation(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function updateLocalMessage(message) {
    setWhatsappConversation((prevState) => {
      const messages = [...prevState.messages];

      return {
        ...prevState,
        messages: [
          ...messages,
          {
            q: " ",
            a: message,
            aTimestamp: new Date().getTime(),
          },
        ],
      };
    });
  }

  const showChatInputBox =
    whatsappConversation?.humanAgentAssignmentSettings?.humanAgentAssigned
      ?.email === user.email;

  const listener = (event) => {
    fetchWhatsappConversationById(
      selectedConversation?.project,
      selectedConversation._id
    );
  };

  const agentId =
    whatsappConversation?.humanAgentAssignmentSettings?.humanAgentAssigned?._id;

  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", listener);
    return () =>
      navigator.serviceWorker.removeEventListener("message", listener);
  }, [selectedConversation, listener]);

  useEffect(() => {
    console.log({ whatsappConversation });
  }, [whatsappConversation]);

  return (
    <MessageView key={selectedConversation._id}>
      <MessageViewHeader>
        <MessageViewContact onContactClick={onViewContactClick}>
          <SourceIcon>
            {ConversationSourceIcon[selectedConversation.sourceOfConversation]}
          </SourceIcon>
          <MessageViewHeaderTitle>
            {whatsappConversation?.userIdentifier ||
              whatsappConversation?.sourceOfConversation}
          </MessageViewHeaderTitle>
        </MessageViewContact>
        <div className="flex gap-x-2 items-center">
          {allowActiveUserToAssign && (
            <Button
              outline
              onClick={() =>
                setAssignAgent({
                  isOpen: true,
                  mode: AssignmentMode.ASSIGN,
                })
              }
            >
              Assign
            </Button>
          )}
          {allowActiveUserToResolve && (
            <ResolveConversation
              projectId={selectedConversation.project}
              conversationId={selectedConversation._id}
              fetchConversations={fetchConversations}
              resetSelectedConversation={resetSelectedConversation}
            />
          )}
          {allowActiveUserToReassign && (
            <Button
              outline
              onClick={() =>
                setAssignAgent({
                  isOpen: true,
                  mode: AssignmentMode.RE_ASSIGN,
                })
              }
            >
              Reassign
            </Button>
          )}
          <ConversationMoreOptions
            onDeleteConversation={onDeleteConversation}
            onRetryExtraction={onRetryExtraction}
          />
        </div>
      </MessageViewHeader>
      <MessageViewBody>
        <RichTextMessages
          messages={whatsappConversation?.messages}
          projectId={whatsappConversation.project}
          conversationId={whatsappConversation._id}
          timeZone={timeZone}
        />
      </MessageViewBody>
      {allowActiveUserToSendMessage && (
        <MessageFooter>
          <ChatEditor
            conversation={whatsappConversation}
            updateLocalMessage={updateLocalMessage}
            onSuccess={listener}
          />
        </MessageFooter>
      )}
      {allowActiveUserToStartWithATemplate && <MessageFooter></MessageFooter>}
      {assignAgent.isOpen && (
        <AgentAssign
          mode={assignAgent.mode}
          isOpen={assignAgent.isOpen}
          onClose={() =>
            setAssignAgent({
              isOpen: false,
              mode: "",
            })
          }
          agentId={agentId}
          projectId={selectedConversation.project}
          conversationId={selectedConversation._id}
          fetchConversations={fetchConversations}
          resetSelectedConversation={resetSelectedConversation}
        />
      )}
    </MessageView>
  );
}

function RichTextMessage(props) {
  const { conversationId, projectId, ...restProps } = props;
  const { message, className, user, timestamp, timeZone } = restProps;

  const { isMediaMessage, mediaType, mediaId } = checkForMediaMessage(message);

  if (isMediaMessage) {
    return MediaMessages[mediaType]({
      projectId: projectId,
      conversationId: conversationId,
      mediaId,
      className,
      user,
      timestamp,
      timeZone
    });
  }

  return <Message {...restProps} />;
}

function RichTextMessages(props) {
  const { messages, projectId, conversationId, timeZone } = props;

  return messages?.map((item, index) => (
    <React.Fragment key={index}>
      <RichTextMessage
        className="flex justify-start"
        message={item?.q ?? ""}
        timestamp={item?.qTimestamp}
        user={false}
        projectId={projectId}
        conversationId={conversationId}
        timeZone={timeZone}
      />
      <RichTextMessage
        className="flex justify-end"
        message={item.a}
        timestamp={item?.aTimestamp}
        user={true}
        projectId={projectId}
        conversationId={conversationId}
        timeZone={timeZone}
      />
    </React.Fragment>
  ));
}

export function TextMessages(props) {
  const { messages, timeZone } = props;

  return messages?.map((item, index) => (
    <React.Fragment key={index}>
      <Message
        className="flex justify-start"
        message={item.q}
        timestamp={item?.qTimestamp}
        user={false}
        timeZone={timeZone}
      />
      <Message
        className="flex justify-end"
        message={item.a}
        timestamp={item?.aTimestamp}
        user={true}
        timeZone={timeZone}
      />
    </React.Fragment>
  ));
}

export function ConversationMessagesView(props) {
  const {
    selectedConversation,
    onDeleteConversation,
    onRetryExtraction,
    onViewContactClick,
    timeZone,
  } = props;
  return (
    <MessageView>
      <MessageViewHeader>
        <MessageViewContact onContactClick={onViewContactClick}>
          <SourceIcon>
            {ConversationSourceIcon[selectedConversation.sourceOfConversation]}
          </SourceIcon>
          <MessageViewHeaderTitle>
            {selectedConversation?.userIdentifier ||
              selectedConversation?.sourceOfConversation}
          </MessageViewHeaderTitle>
        </MessageViewContact>
        <ConversationMoreOptions
          onDeleteConversation={onDeleteConversation}
          onRetryExtraction={onRetryExtraction}
        />
      </MessageViewHeader>
      <MessageViewBody>
        <TextMessages
          messages={selectedConversation?.messages}
          timeZone={timeZone}
        />
      </MessageViewBody>
    </MessageView>
  );
}

function Message(props) {
  const { message, timestamp, className, user, timeZone } = props;

  if (message.trim() === "") {
    return null;
  }

  const bubbleColor = user
    ? "bg-blue-700 text-gray-200"
    : "bg-blue-100 text-gray-700";

  return (
    <div className={className}>
      <div className="flex items-end">
        <div className={`max-w-sm md:max-w-md rounded-lg ${bubbleColor} p-4`}>
          <p className="text-sm break-words">{message}</p>
          {timestamp && (
            <div className="flex justify-end mt-2">
              <span className="text-xs">
                {formatTimestampBasedOnTimezone(timestamp, timeZone)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function MessageViewHeader({ children }) {
  return (
    <div className="flex justify-between items-center p-6 border-b border-gray-200 sticky top-0">
      {children}
    </div>
  );
}

function MessageViewHeaderTitle({ children }) {
  return <h2 className="text-xl font-semibold text-gray-800">{children}</h2>;
}

function MessageViewContact({ onContactClick, children }) {
  return (
    <div onClick={onContactClick} className="flex gap-x-2 cursor-pointer">
      {children}
    </div>
  );
}

function MessageViewBody({ children }) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, []);

  return (
    <div ref={ref} className="overflow-y-auto flex-grow p-6 space-y-4">
      {children}
    </div>
  );
}

function MessageView({ id, children }) {
  return (
    <div className="w-2/4 border-r border-gray-200 flex flex-col flex-auto">
      {children}
    </div>
  );
}

function MessageFooter({ children }) {
  return <>{children}</>;
}
