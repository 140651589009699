import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import lottie from "lottie-web";
import success from "../../animation/succesful.json";
import { Button } from "../../common/button";
import * as thirdpartyService from "../../services/thirdpartyService";
import { message } from "antd";
import httpService from "V2.0/services/httpService";

function ThirdPartAuthSuccess(props) {
  const container = useRef(null);

  // Using navigate if needed later, since it's declared
  const navigate = useNavigate();

  useEffect(() => {
    let anim;
    if (container.current) {
      anim = lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: success,
      });
    }

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code") || "";
    const locationParam = queryParams.get("location") || "";
    const accountsServer = queryParams.get("accounts-server") || "";
    const projectId = localStorage.getItem("projectId") || "";
    const type = localStorage.getItem("authCategory") || "";

    const checkTokenAsync = async () => {
      try {
        console.log({ code, type, projectId });

        const response = await thirdpartyService.checkToken(
          projectId,
          code,
          locationParam,
          accountsServer,
          type
        );
        if (response.status === 200)
          if (type === "gmail") {
            const defaultLabelResponse = await httpService.post(
              `/tpauth/createGmailLabel/${projectId}`,
              {
                labelName: "AI-TO-REPLY",
              }
            );
            if (defaultLabelResponse.status === 201) {
              message.success("Authentication succesful");
            }
          } else {
            message.success("Authentication succesful");
          }
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status < 500
        ) {
          message.error(ex.response.data.msg);
        }
      }
    };

    checkTokenAsync();

    return () => {
      if (anim) {
        anim.destroy();
      }
    };
  }, []); //
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div ref={container}></div>
      <Button onClick={() => navigate("/")}>Take me home</Button>
    </div>
  );
}

export default ThirdPartAuthSuccess;
