import Joi from "joi-browser";
import { useState, useEffect } from "react";
import Form from "V2.0/components/Form";

const template = [
  { name: "name", label: "Name *", type: "text" },
  {
    name: "description",
    label: "Description",
    type: "text",
  },
  {
    name: "delaySeconds",
    label: "Delay (seconds) *",
    type: "number",
  },
];

const schema = {
  name: Joi.string().required().label("Name"),
  description: Joi.string().allow(null, "").label("Description"),
  delaySeconds: Joi.number().required().label("Delay Seconds"),
};

export function DelaySettings(props) {
  const { node, setNodes, closePanel } = props;
  const [state, setState] = useState({
    name: node?.data?.settings?.name || "",
    description: node?.data?.settins?.description || "",
    delaySeconds: node?.data?.settings?.delaySeconds || "",
  });
  const [loading, setLoading] = useState(false);

  function handleSave(data) {
    setNodes((nodes) => {
      const updatedNodes = nodes.map((_node) => {
        if (_node.id === node.id) {
          const { selected, ...rest } = _node;
          return {
            ...rest,
            selected: false,
            data: {
              ...node.data,
              settings: { ...node.data.settings, ...data },
            },
          };
        } else {
          return _node;
        }
      });
      return updatedNodes;
    });
    closePanel();
  }

  return (
    <div className="flex flex-col gap-y-4">
      <Form
        template={template}
        schema={schema}
        inputField={state}
        buttonInfo={{ label: "Save", style: "right-side" }}
        loading={loading}
        enableActionsOnChange={true}
        setLoading={setLoading}
        errorMessage={{}}
        onSubmit={handleSave}
      />
    </div>
  );
}
