import { IntegrationShell } from "../common/Shell";
import { Button } from "V2.0/common/button";
import GmailLogo from "V2.0/images/gmail.png";

import { useOutletContext } from "react-router-dom";

function GMail(props) {
  const { projectId } = props;

  const connectGmail = async () => {
    localStorage.setItem("projectId", projectId);
    localStorage.setItem("authCategory", "gmail");

    const options = {
      baseURL: "https://accounts.google.com/o/oauth2/v2/auth",
      response_type: "code",
      redirectUri: process.env.REACT_APP_ZOHO_REDIRECT,
      clientId: process.env.REACT_APP_GMAIL_CLIENT_ID,
      scopes: ["https://www.googleapis.com/auth/gmail.modify"],
      include_granted_scopes: "true",
      access_type: "offline",
      prompt: "consent",
    };

    const scopeString = options.scopes.join(" ");

    // Construct the GHL OAuth URL
    const gmailAuthURL =
      `${options.baseURL}?` +
      `response_type=${encodeURIComponent(options.response_type)}&` +
      `redirect_uri=${encodeURIComponent(options.redirectUri)}&` +
      `client_id=${encodeURIComponent(options.clientId)}&` +
      `scope=${encodeURIComponent(scopeString)}&` +
      `include_granted_scopes=${encodeURIComponent(
        options.include_granted_scopes
      )}&` +
      `access_type=${options.access_type}&` +
      `prompt=${options.prompt}`;

    window.open(gmailAuthURL, "_blank");
  };

  return (
    <div>
      <div class="flex flex-col items-center">
        <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 border rounded-lg">
          <div className="flex gap-x-2 items-center">
            <img src={GmailLogo} className="size-6 inline" />

            <h3 className="text-lg font-semibold leading-6 text-gray-900">
              Gmail
            </h3>
          </div>
          <div className="mt-4 text-sm text-gray-500">
            <p>
              Connect your Gmail account to seamlessly integrate your agent to
              manage your emails
            </p>
          </div>

          <div className="">
            <Button onClick={connectGmail} className="mt-10 w-full">
              Connect Gmail
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function GMailIntegraion() {
  const { projectId } = useOutletContext();
  return (
    <IntegrationShell>
      <GMail projectId={projectId} />
    </IntegrationShell>
  );
}
