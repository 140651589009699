import {
  ArrowPathIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  CheckIcon,
  ExclamationCircleIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { message, Tooltip } from "antd";
import { Chip } from "components/FilterChip";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Badge, BadgeButton } from "V2.0/common/badge";
import { Button } from "V2.0/common/button";
import {
  Dialog,
  DialogBody,
  DialogTitle,
  DialogActions,
} from "V2.0/common/dialog";
import { Input } from "V2.0/common/input";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "V2.0/common/table";
import { useCompanyProjects } from "V2.0/Context/CompanyProjectsContext";
import httpService from "V2.0/services/httpService";

function useCustomers(project) {
  const [customers, setCustomers] = useState({
    data: {},
    isLoading: true,
  });

  const setLoading = (state) =>
    setCustomers((prevState) => ({ ...prevState, isLoading: state }));
  const setData = (state) => setCustomers({ data: state, isLoading: false });

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await httpService.get(`/customers/${project._id}`);
      const data = response.data;
      const customers = data.reduce(
        (acc, customer) => ({
          ...acc,
          [customer._id]: customer,
        }),
        {}
      );
      setData(customers);
    } catch (error) {
      console.error("error");
    }
  };

  useEffect(() => {
    if (project) {
      fetchCustomers();
    }
  }, [project]);

  return { customers };
}

function useProjectsMap(projects) {
  const whitelabelProjects = projects.filter((project) => project.createdVia === "Whitelabel")

  const projectsMap = projects.reduce((acc, project) => {
    return {
      ...acc,
      [project._id]: project,
    };
  }, {});

  return {
    projectsMap,
    whitelabelProjects
  };
}

function useQuotaManager() {
  const [quotas, setQuotas] = useState({});

  const addQuota = (projectId, value, key) => {
    setQuotas((prevQuotas) => {
      if (projectId in prevQuotas) {
        return {
          ...prevQuotas,
          [projectId]: {
            ...prevQuotas[projectId],
            [key]: value,
          },
        };
      }

      return {
        ...prevQuotas,
        [projectId]: {
          projectId,
          [key]: value,
        },
      };
    });
  };

  const resetQuota = (projectId, key) => {
    setQuotas((prevQuotas) => {
      const { [projectId]: projectQuota, ...rest } = prevQuotas;

      if (key in projectQuota) {
        const { [key]: value, projectId } = projectQuota;
        return {
          ...prevQuotas,
          [projectId]: {
            [key]: value,
            projectId,
          },
        };
      }

      return {
        ...rest,
      };
    });
  };

  const removeVoiceSecLimit = (projectId) =>
    resetQuota(projectId, "allowedQuestions");
  const removeAllowedQuestions = (projectId) =>
    resetQuota(projectId, "voiceSecLimit");

  const addVoiceSecLimitQuota = (projectId, value) =>
    addQuota(projectId, value, "voiceSecLimit");
  const addAllowedQuestionsQuota = (projectId, value) =>
    addQuota(projectId, value, "allowedQuestions");

  const resetQuotas = () => setQuotas({});

  const isQuotasEmpty = Object.keys(quotas).length === 0;

  return {
    quotas,
    isQuotasEmpty,
    resetQuotas,
    removeAllowedQuestions,
    removeVoiceSecLimit,
    addVoiceSecLimitQuota,
    addAllowedQuestionsQuota,
  };
}

function QuotaInput(props) {
  const { updateLimit, min, max, value, disableSave, onReset, reset, quota } = props;

  const [state, setState] = useState(value);
  const [error, setError] = useState({ isError: false, message: "" });

  const [enableReset, setEnableReset] = useState(false);

  const resetValue = () => {
    setState(quota);
    setEnableReset(false);
    updateLimit(quota);
    onReset();
    setError({ isError: false, message: "" });
  };

  const validate = (value) => {

    if (value < min) {
      setError({
        isError: true,
        message: "Insufficient quota",
      });
      disableSave(true);
      return true;
    }

    if (value > max) {
      setError({ isError: true, message: "Insufficient quota" });
      disableSave(true);
      return true;
    }
    disableSave(false);
    return false;
  };

  const handleBlur = (value) => {
    if (validate(value)) {
      updateLimit(value);
      return;
    }
    updateLimit(value);
  };

  useEffect(() => {
    setState(quota);
    setEnableReset(false);
    setError({ isError: false, message: "" });
  }, [reset]);

  useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value]);

  useEffect(() => {
    if (quota != value) {
      setEnableReset(true);
      validate(value);
    }
  }, [quota]);

  return (
    <div className="flex items-center gap-x-2">
      <Input
        invalid={error.isError}
        min={min}
        max={max}
        className="w-[150px]"
        type="number"
        onChange={(e) => {
          setEnableReset(true);
          setError({ isError: false, message: "" });
          const value = Number(e.target.value);

          setState(value);

          handleBlur(value);
        }}
        // onBlur={handleBlur}
        value={state}
      />

      <div className={error.isError ? "visible" : "invisible"}>
        <Tooltip title={error.message} color="error">
          <ExclamationCircleIcon className="size-5 text-red-500 cursor-pointer" />
        </Tooltip>
      </div>

      <div>
        <Button
          plain
          onClick={resetValue}
          className={enableReset ? "visible" : "invisible"}
        >
          <ArrowPathIcon />
        </Button>
      </div>
    </div>
  );
}

function useCalculateAvailable(company, projects) {
  const [availableCallTime, setAvailableCallTime] = useState(0);
  const [availableQuestions, setAvailableQuestions] = useState(0);

  const calculateAvailableQuotas = useCallback(() => {
    if (company && projects) {
      const { callLimit, questionsLimit } = projects.reduce(
        (acc, project) => {
          const { voiceSecLimit } = project;
          const allowedQuestions = project?.allowedQuestions || 0;
          const { callLimit, questionsLimit } = acc;

          return {
            callLimit: callLimit + (voiceSecLimit || 0),
            questionsLimit: questionsLimit + allowedQuestions,
          };
        },
        { callLimit: 0, questionsLimit: 0 }
      );

      setAvailableCallTime(company.voiceSecLimit - callLimit);
      setAvailableQuestions(company.allowedQuestions - questionsLimit);
    }
  }, [company, projects]);

  useEffect(() => {
    calculateAvailableQuotas();
  }, []);

  return {
    availableCallTime,
    setAvailableCallTime,
    availableQuestions,
    setAvailableQuestions,
    calculateAvailableQuotas,
  };
}

const FILTERS = {
  ALL: "All",
  DEFAULT: "Default",
  WHITELABEL: "Whitelabel",
};

export function QuotaManager() {
  const { company, projects, fetchProjects } = useCompanyProjects();
  const { projectsMap, whitelabelProjects } = useProjectsMap(projects);
  const { customers } = useCustomers(projects[0]);
  const { data, isLoading } = customers;

  const {
    quotas,
    removeAllowedQuestions,
    removeVoiceSecLimit,
    addAllowedQuestionsQuota,
    addVoiceSecLimitQuota,
    isQuotasEmpty,
    resetQuotas,
  } = useQuotaManager();
  const {
    availableCallTime,
    setAvailableCallTime,
    setAvailableQuestions,
    availableQuestions,
    calculateAvailableQuotas,
  } = useCalculateAvailable(company, projects);
  const [disableSave, setDisableSave] = useState(false);
  const [reset, setReset] = useState(false);
  const [activeFilter, setActiveFilter] = useState(FILTERS.WHITELABEL);

  const filteredProjects = useMemo(() => {
    if (activeFilter === FILTERS.ALL) {
      return projects;
    }

    return projects.filter((project) => project.createdVia === activeFilter);
  }, [activeFilter, projects]);

  if (isLoading) {
    return;
  }

  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-x-2">
          {Object.values(FILTERS).map((filter, idx) => {
            return (
              <Chip
                key={idx}
                label={filter}
                onClick={() => setActiveFilter(filter)}
                isActive={filter === activeFilter}
              />
            );
          })}
        </div>
        <div className="flex gap-x-4">
          <Button
            disabled={isQuotasEmpty}
            outline
            onClick={() => {
              resetQuotas();
              calculateAvailableQuotas();
              setReset(!reset);
            }}
          >
            Reset
          </Button>
          <ReviewQuotaAndSave
            disabled={isQuotasEmpty || disableSave}
            quotas={quotas}
            projectsMap={projectsMap}
            companyId={company._id}
            onSave={fetchProjects}
          />
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-4">
        <div className="border rounded-lg p-4 flex flex-col gap-y-2">
          <p className="text-sm font-sm text-gray-600">Calls Quota</p>
          <p className="text-2xl font-semibold">
            {company.voiceSecLimit}
            <span className="text-sm text-gray-600 mx-2">seconds</span>
          </p>
        </div>
        <div className="border rounded-lg p-4 flex flex-col gap-y-2">
          <p className="text-sm font-sm text-gray-600">Messages Quota</p>
          <p className="text-2xl font-semibold">
            {company.allowedQuestions}
            <span className="text-sm text-gray-400 mx-2">messages</span>
          </p>
        </div>
        <div
          className={`border rounded-lg p-4 flex flex-col gap-y-2 ${
            availableCallTime < 0 && "border-red-500"
          }`}
        >
          <p className="text-sm font-sm text-gray-600">Available Calls</p>
          <p
            className={`text-2xl font-semibold ${
              availableCallTime < 0 && "text-red-500"
            }`}
          >
            {availableCallTime}
            <span className="text-sm text-gray-600 mx-2">seconds</span>
          </p>
        </div>
        <div
          className={`border rounded-lg p-4 flex flex-col gap-y-2 ${
            availableQuestions < 0 && "border-red-500"
          }`}
        >
          <p className="text-sm font-sm text-gray-600">Available Messages</p>
          <p
            className={`text-2xl font-semibold ${
              availableQuestions < 0 && "text-red-500"
            }`}
          >
            {availableQuestions}
            <span className="text-sm text-gray-600 mx-2">messages</span>
          </p>
        </div>
      </div>
      <Table className="max-h-[calc(100vh-300px)] overflow-auto shadow-inner">
        <TableHead className="sticky top-0 z-10 bg-white shadow-sm">
          <TableRow>
            <TableHeader>Workspace</TableHeader>
            <TableHeader>Customer</TableHeader>
            <TableHeader>Calls Quota (seconds)</TableHeader>
            <TableHeader>Messages Quota (messages)</TableHeader>

            <TableHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProjects.map((project) => {
            const {
              _id,
              name,
              createdBy,
              voiceSecLimit,
              allowedQuestions,
              usage,
              createdVia,
            } = project;
            const { callTime, questions } = usage.current;
            const customer = data[createdBy];
            const { email } = customer || { email: "" };

            let _voiceSecLimit;
            if (_id in quotas && "voiceSecLimit" in quotas[_id]) {
              _voiceSecLimit = quotas[_id].voiceSecLimit;
            } else {
              _voiceSecLimit = voiceSecLimit;
            }

            let _allowedQuestions;
            if (_id in quotas && "allowedQuestions" in quotas[_id]) {
              _allowedQuestions = quotas[_id].allowedQuestions;
            } else {
              _allowedQuestions = allowedQuestions || 0;
            }

            // const availableWorkspaceQuestions = _allowedQuestions - questions;
            // const availableWorkspaceCallTime = _voiceSecLimit - callTime;

            return (
              <TableRow key={_id}>
                <TableCell>
                  <div className="flex gap-x-4">
                    {name}
                    {createdVia === "Whitelabel" && (
                      <BadgeButton color="teal">Whitelabel</BadgeButton>
                    )}
                  </div>
                </TableCell>
                <TableCell>{email || "Default"}</TableCell>
                <TableCell>
                  <div className="flex flex-col gap-y-2">
                    <QuotaInput
                      reset={reset}
                      min={0}
                      max={availableCallTime + _voiceSecLimit}
                      value={_voiceSecLimit}
                      quota={voiceSecLimit}
                      onReset={() => removeVoiceSecLimit(_id)}
                      disableSave={(value) => {
                        setDisableSave(value);
                      }}
                      updateLimit={(value) => {
                        addVoiceSecLimitQuota(_id, value);

                        setAvailableCallTime(
                          availableCallTime - (value - _voiceSecLimit)
                        );
                      }}
                    />

                    <div className="flex gap-x-2">
                      {/* <p className="text-xs">
                        <span className="text-gray-400 text-xs">Used:</span>{" "}
                        {callTime}
                      </p> */}
                      {/* <p
                        className={`text-xs ${
                          availableWorkspaceCallTime < 0 && "text-red-500"
                        }`}
                      >
                        <span className="text-gray-400 text-xs">
                          Available:
                        </span>{" "}
                        {availableWorkspaceCallTime}
                      </p> */}
                    </div>
                  </div>
                </TableCell>
                {createdVia === "Whitelabel" && (
                  <TableCell>
                    <div className="flex flex-col gap-y-2">
                      <QuotaInput
                        reset={reset}
                        min={0}
                        max={availableQuestions + _allowedQuestions}
                        value={_allowedQuestions}
                        quota={allowedQuestions || 0}
                        onReset={() => removeAllowedQuestions(_id)}
                        disableSave={(value) => {
                          setDisableSave(value);
                        }}
                        updateLimit={(value) => {
                          addAllowedQuestionsQuota(_id, value);
                       
                          setAvailableQuestions(
                            availableQuestions - (value - _allowedQuestions)
                          );
                        }}
                      />
                      <div className="flex gap-x-2">
                        {/* <p className="text-xs">
                          <span className="text-gray-400 text-xs">Quota:</span>{" "}
                          {_allowedQuestions}
                        </p> */}

                        {/* <p
                          className={`text-xs ${
                            availableWorkspaceQuestions < 0 && "text-red-500"
                          }`}
                        >
                          <span className="text-gray-400 text-xs">
                            Available:
                          </span>{" "}
                          {availableWorkspaceQuestions}
                        </p> */}
                      </div>
                    </div>
                  </TableCell>
                )}
                {createdVia !== "Whitelabel" && (
                  <TableCell>
                    <p>Not applicable</p>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function ReviewQuotaAndSave(props) {
  const { disabled, quotas, projectsMap, companyId, onSave } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClose = () => setIsOpen(false);

  async function handleSave() {
    setLoading(true);
    message.info("Updating quota");

    const payload = {
      updateData: Object.values(quotas),
    };

    try {
      const response = await httpService.post(
        `/project/updateProjectQuota/${companyId}`,
        payload
      );

      if (response.status === 200) {
        message.success("Project Quota updated");
        setLoading(false);
        onSave();
        onClose();
      }
    } catch (error) {
      message.error("Failed to update quota");
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <>
      <Button disabled={disabled} onClick={() => setIsOpen(true)}>
        Review & Save
      </Button>
      <Dialog size="2xl" open={isOpen} onClose={() => {}}>
        <DialogTitle>Review Quota & Save</DialogTitle>
        <DialogBody className="">
          <Table>
            <TableHead>
              <TableCell>Workspace</TableCell>
              <TableCell>Call Time (seconds)</TableCell>
              <TableCell>Messages</TableCell>
            </TableHead>
            <TableBody>
              {Object.values(quotas).map((quota, idx) => {
                return (
                  <TableRow>
                    <TableCell>{projectsMap[quota.projectId].name}</TableCell>
                    <TableCell>{quota?.voiceSecLimit}</TableCell>
                    <TableCell>{quota?.allowedQuestions}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogBody>
        <DialogActions>
          <Button disabled={loading} outline onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
