import { ChevronDownIcon } from "@heroicons/react/20/solid";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../../common/dropdown.jsx";

export function WorkspaceActionsMenu(props) {
  const { onDelete, onRename, onDuplicate, isCurrentUserCompanyOwner } = props;
  return (
    <Dropdown>
      <DropdownButton plain className="rounded-full">
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu>
        <DropdownItem onClick={onDuplicate}>Duplicate</DropdownItem>
        {isCurrentUserCompanyOwner && (
          <DropdownItem onClick={onRename}>Rename</DropdownItem>
        )}
        {isCurrentUserCompanyOwner && (
          <DropdownItem onClick={onDelete}>Delete</DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
